import useDocumentTitle from '../components/useDocumentTitle';

const About = () => {
    useDocumentTitle('About 🤖');
    return (
        <>
            <h1>About Page</h1>
            {/*<!-- start slipsum code -->*/}
            <p>Now that we know who you are, I know who I am. I'm not a mistake! It all makes sense! In a comic, you know how you can tell who the arch-villain's going to be? He's the exact opposite of the hero. And most times they're friends, like you and me! I should've known way back when... You know why, David? Because of the kids. They called me Mr Glass. </p>
            <p>Well, the way they make shows is, they make one show. That show's called a pilot. Then they show that show to the people who make shows, and on the strength of that one show they decide if they're going to make more shows. Some pilots get picked and become television programs. Some don't, become nothing. She starred in one of the ones that became nothing. </p>
            {/*<!-- end slipsum code -->*/}
        </>
        )
    ;
};
  
export default About;