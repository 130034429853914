const adjectives = ['Amazing', 
                    'Super', 
                    'Value', 
                    'Budget', 
                    'Great', 
                    'Premium', 
                    'Luxury',
                    'Boss',
                    'Essential',
                    'Rare'
                 ];

const product = ['Jetpack',
                 'Proton Pack',
                 'Mr Fusion',
                 'Tricorder',
                 'Hoverboard',
                 'Neuralyzer',
                 'Voight-Kampff Machine',
                 'Sonic Screwdriver',
                 'Explosive Pen',
                 'Laser Watch'
                ];

const randomProduct = () => { 

    const productCode = new Date().getTime().toString().slice(-3);
    const digits = productCode.split('');
    
    const title = [
        adjectives[parseInt(digits[0])],
        product[parseInt(digits[1])],
        (parseInt(digits[2]) + 1) * 1000 
    ].join(' ').trimEnd();
    
    return { 
        productCode : productCode,
        title : title
    };
}

export default randomProduct;