import React from "react";
import randomProduct from "../helpers/randomProduct";

const BuyButton = () => { 

    const doTransaction = () => { 

        const orderId = new Date().getTime().toString();
        const orderValue = ((Math.random() * 99) + 1).toFixed(2);

        const item = randomProduct();

        window.gtag("event", "purchase", {
            transaction_id: orderId,
            value: orderValue,
            currency: "GBP",
            items: [{
                item_id: item.productCode,
                item_name: item.title,
                price: orderValue
            }]
        });

        console.log(`Simulated purchase ${orderId} for ${orderValue}`);
    }

    return (<button onClick={doTransaction}>
                Simulate Purchase
            </button>
    );
}

export default BuyButton; 