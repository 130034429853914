import BuyButton from '../components/BuyButton';
import useDocumentTitle from '../components/useDocumentTitle';

const Home = () => {
    useDocumentTitle('Home 🏠');
    return (<>
        <h1>Home</h1>
        {/*<!-- start slipsum code -->*/}
        <p>
            Do you see any Teletubbies in here? Do you see a slender plastic tag clipped to my shirt with my name printed on it? Do you see a little Asian child with a blank expression on his face sitting outside on a mechanical helicopter that shakes when you put quarters in it? No? Well, that's what you see at a toy store. And you must think you're in a toy store, because you're here shopping for an infant named Jeb. 
        </p>
        <p>
            Now that there is the Tec-9, a crappy spray gun from South Miami. This gun is advertised as the most popular gun in American crime. Do you believe that shit? It actually says that in the little book that comes with it: the most popular gun in American crime. Like they're actually proud of that shit.  
        </p>
        <p>
            Your bones don't break, mine do. That's clear. Your cells react to bacteria and viruses differently than mine. You don't get sick, I do. That's also clear. But for some reason, you and I react the exact same way to water. We swallow it too fast, we choke. We get some in our lungs, we drown. However unreal it may seem, we are connected, you and I. We're on the same curve, just on opposite ends. 
        </p>
        {/*<!-- end slipsum code -->*/}
        <BuyButton />
    </>
    );
  };
  
  export default Home;