import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <div className="row m-4">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
          rel="stylesheet" />
        <nav className="col-4">
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/about">About</Link>
                </li>
            </ul>
        </nav>
        <div className="col-8">
            <Outlet />
        </div>
    </div>
  )
};

export default Layout;