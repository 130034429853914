import useDocumentTitle from '../components/useDocumentTitle';

const NoPage = () => {
    useDocumentTitle('404 - Page not found 😤🙈');
    return (
        <>
            <h1>404 - Page Not Found😤</h1>
            <iframe title="404 Image" src="https://giphy.com/embed/9M5jK4GXmD5o1irGrF" width="419" height="480" />
        </>
    );
  };
  
  export default NoPage;